<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45">
    <g>
      <g>
        <rect width="45" height="45" rx="8" ry="8" fill="#fff" />
        <rect
          fill="none"
          stroke="currentColor"
          stroke-width="3"
          x="1.5"
          y="1.5"
          width="42"
          height="42"
          rx="6.5"
          ry="6.5"
        />
      </g>
      <path
        fill="currentColor"
        d="m38.08,31.35h-21.81v-1.04c0-.57-.47-1.04-1.04-1.04h-2.08c-.57,0-1.04.47-1.04,1.04v1.04h-5.19c-.57,0-1.04.47-1.04,1.04v2.08c0,.57.47,1.04,1.04,1.04h5.19v1.04c0,.57.47,1.04,1.04,1.04h2.08c.57,0,1.04-.47,1.04-1.04v-1.04h21.81c.57,0,1.04-.47,1.04-1.04v-2.08c0-.57-.47-1.04-1.04-1.04Zm0-10.39h-5.2v-1.04c0-.57-.47-1.04-1.04-1.04h-2.08c-.57,0-1.04.47-1.04,1.04v1.04H6.92c-.57,0-1.04.47-1.04,1.04v2.08c0,.57.47,1.04,1.04,1.04h21.81v1.04c0,.57.47,1.04,1.04,1.04h2.08c.57,0,1.04-.47,1.04-1.04v-1.04h5.2c.57,0,1.04-.47,1.04-1.04v-2.08c0-.57-.47-1.04-1.04-1.04h0Zm0-10.39h-13.5v-1.04c0-.57-.47-1.04-1.04-1.04h-2.08c-.57,0-1.04.47-1.04,1.04v1.04H6.92c-.57,0-1.04.47-1.04,1.04v2.08c0,.57.47,1.04,1.04,1.04h13.5v1.04c0,.57.47,1.04,1.04,1.04h2.08c.58,0,1.04-.46,1.05-1.04h0v-1.04h13.5c.57,0,1.04-.47,1.04-1.04v-2.08c0-.57-.47-1.04-1.04-1.04h0Z"
      />
    </g>
  </svg>
</template>
